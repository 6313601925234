import React, { useEffect, useContext, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
// import HomeIcon from "@material-ui/icons/Home";
import FolderIcon from "@material-ui/icons/Folder";
import NotificationsIcon from "@material-ui/icons/Notifications";
import User from "./User";
import { AuthContext } from "../../context/Auth";
import { IconButton } from "@material-ui/core";
import Link from "@material-ui/core/Link";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "fixed",
    top: "auto",
    bottom: 0,
    backgroundColor: "white",
    padding: 0,
    justifyContent: "center",
    height: "10vh",
  },
  navBottom: {
    width: "100%",
  },
  button: {
    fontSize: 10,
    fontFamily: "Whitney",
    minWidth: 60,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  buttonActive: {
    color: "#906d31 !important",
    fill: "#906d31",
    backgroundColor: "#f6f1eb",
    fontSize: 10,
    fontFamily: "Whitney",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    minWidth: 60,
  },
  containerLogo: {
    position: "relative",
    width: "100%",
    height: "20vh",
    backgroundColor: "black",

    overflow: "hidden",
  },
  logo: {
    maxWidth: 245,
    height: "100%",
    maxHeight: "10vh",
    backgroundColor: "transparent",
  },

  fab: {
    width: 70,
    height: 70,
    marginBottom: 50,
    marginRight: 15,
    borderRadius: 45,
    border: 0,
    backgroundColor: "white",
    boxShadow: "6px 7px 13px -6px rgba(0,0,0,0.75)",
  },
  iconHome: {
    color: "#656565",
    width: 16,
    height: 16,
    margin: 4,
  },
  buttonHome: {
    color: "black",
    padding: 14,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
  const { newNoticia, toggleNoticia } = useContext(AuthContext);

  useEffect(() => {
    updateCurrentPath(); // eslint-disable-next-line
  }, [window.location.pathname]);

  const updateCurrentPath = () => {
    switch (window.location.pathname) {
      case "/download":
        setValue(0);
        break;
      case "/news":
        setValue(1);
        break;
      default:
        setValue(10);
        break;
    }
  };
  const redirect = () => {
    if (status.isMobile.iOS()) document.querySelector("#iphone").click();
    else history.push("/download");
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.containerLogo}
      >
        <IconButton
          aria-label="Home"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          component={RouterLink}
          to="/"
          className={classes.buttonHome}
        >
          <img
            alt={"home"}
            src={require("../../assets/HomeIcon.svg")}
            className={classes.iconHome}
          />
        </IconButton>
        <Link
          style={{ textAlign: "center", height: "10vh" }}
          component={RouterLink}
          to="/"
        >
          <img
            alt="logo"
            className={classes.logo}
            src={require("../../assets/errazuriz.svg")}
          />
        </Link>
        <User />
      </Grid>
      <AppBar id={"app-navbar-button"} className={classes.appBar}>
        <Toolbar
          style={{
            padding: 0,
            textAlign: "center",
            justifyContent: "space-evenly",
          }}
        >
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              console.log(newValue);
            }}
            showLabels
            className={classes.navBottom}
          >
            <BottomNavigationAction
              onClick={redirect}
              className={value === 0 ? classes.buttonActive : classes.button}
              label="DOWNLOAD"
              icon={<FolderIcon />}
            />

            <BottomNavigationAction
              component={RouterLink}
              to="/news"
              className={value === 1 ? classes.buttonActive : classes.button}
              label="NEWS"
              onClick={toggleNoticia}
              icon={
                newNoticia === true ? (
                  <Badge badgeContent={""} color="primary">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )
              }
            />
            <BottomNavigationAction
              component={RouterLink}
              to="/chat"
              label=""
              icon={
                <div style={{ width: 130 }}>
                  <button
                    className={classes.fab}
                  
                    to="/admin/chat"
                    tag={RouterLink}
                  >
                    <img
                      alt="msj"
                      src={require("../../assets/icono_chat.svg")}
                      style={{ width: 50, height: 50 }}
                    />
                  </button>
                </div>
              }
            />
          </BottomNavigation>
          <Link
            id={"iphone"}
            style={{ height: 1, width: 1,visibility:"hidden" }}
            target="_blank"
            rel="noopener"
            href="https://download.errazuriz.cl"
            >{"."}</Link>
        </Toolbar>
      </AppBar>
      {props.children}
    </div>
  );
};

export default Header;
