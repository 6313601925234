import React from "react";
import { watchUserChanges } from "../services/firebase";

export const AuthContext = React.createContext({});
export const AuthContextConsumer = AuthContext.Consumer;

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [authReady, setAuthReady] = useState(false);
//   const [pending, setPending] = useState(true);
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     let userWatcherUnsub = watchUserChanges((response) => {
//       if (response) {
//         setAuthReady(true);
//         setIsLoggedIn(true);
//         setUser(response);
//         console.log(user)
//         debugger;
//       } else {
//         setAuthReady(true);
//         setIsLoggedIn(false);
//         setUser(null);
//       }
//     });
//     console.log(user)
//     debugger;
//     return userWatcherUnsub();
//   });

//   // if (isLoggedIn) {
//   //   return (
//   //     <div
//   //       style={{
//   //         display: "flex",
//   //         justifyContent: "center",
//   //         alignItems: "center",
//   //         color: "#656565",
//   //         fontFamily: "Basker",
//   //         width: "100%",
//   //         height: "100vh",
//   //       }}
//   //     >
//   //       {" "}
//   //       <h1>Loading...</h1>{" "}
//   //     </div>
//   //   );
//   // }

//   return (
//     <AuthContext.Provider
//       value={{
//         isLoggedIn,
//         authReady,
//         user,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };


export class AuthProvider extends React.Component {
  state = {
      isLoggedIn: false,
      authReady: false,
      user: null,
  }

  componentDidMount() {
      this.userWatcherUnsub = watchUserChanges((user) => {
        // console.log(user)
        // debugger;
          if (user) {
              this.setState({
                  authReady: true,
                  isLoggedIn: true,
                  user,
              });
          } else {
              this.setState({
                  authReady: true,
                  isLoggedIn: false,
                  user: null,
              });
          }
      });
  }

  componentWillUnmount() {
      if (this.userWatcherUnsub) {
          this.userWatcherUnsub();
      }
  }

  render() {
      const {
          children,
      } = this.props;

      return (
          <AuthContext.Provider
              value={{
                  ...this.state,
              }}
          >
              {children}
          </AuthContext.Provider>
      );
  }
}
