import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth";
// import Header from "../components/layout/Header";
import HeaderAdmin from "../components/layout/HeaderAdmin";

const PrivateRouteAdmin = ({ component: RouteComponent, ...rest }) => {
  const {  user,isLoggedIn } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn? (
          user.type === undefined ? (
            <Redirect to={"/"} />
          ) : (
            <HeaderAdmin>
              <RouteComponent {...routeProps} />
            </HeaderAdmin>
          )
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRouteAdmin;
