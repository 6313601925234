import React, { useContext } from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// modal
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NoticeContext } from "../../context/Notices";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
 
  buttonAssigned: {
    color: "white",
    borderRadius: 20,
    backgroundColor: "#906d31",
    margin: 10,
    "&:hover": {
      backgroundColor: "#906d31",
    },
    "&:after": {
      backgroundColor: "#906d31",
    },
    "&:before": {
      backgroundColor: "#906d31",
    },
  },
  buttonCancel: {
    color: "#5c5c5c",
    borderColor: "#5c5c5c",
    borderRadius: 20,
    backgroundColor: "#ffffff",
    boxShadow: "",
    margin: "10px 0 10px 0",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
  },
}));

const Modal = ({ open, toggle, uid }) => {
  const classes = useStyles();
  const { deleteNotice } = useContext(NoticeContext);
  const onClickSubmit = () => {
    deleteNotice(uid);
    toggle(!open);
  };

  return (
    <Dialog
      open={open}
      onClose={() => toggle(!open)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"DELETE NEWS"}</DialogTitle>
      <DialogContent>
        <Typography>Do you want to delete this news?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggle(!open)} className={classes.buttonCancel}>
          Back
        </Button>
        <Button onClick={onClickSubmit} className={classes.buttonAssigned}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
