import React from 'react';
import {
    watchUserChanges,
    watchNotices,
    createNotice,
    deleteNotice,
    // updateNotice,
} from '../services/firebase';

export const NoticeContext = React.createContext({});

export const NoticeContextConsumer = NoticeContext.Consumer;

export class NoticeContextProvider extends React.Component {
    state = {
        notices: [],
    }

    componentDidMount() {
        this.userWatcherUnsub = watchUserChanges((user) => {
            if (user && !this.expenseWatcherUnsub) {
                this.expenseWatcherUnsub = watchNotices((notices) => {
                    this.setState({ notices });
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.expenseWatcherUnsub) {
            this.expenseWatcherUnsub();
        }
    }

    deleteNotice = async (id) => {
        try {
            await deleteNotice(id);
        } catch (error) {
            console.log(error);
        }
    }

    createNotice = async (data) => {
        try {
            await createNotice(data);
        } catch (error) {
            console.log(error);
        }
    }

    // updateExpense = async (id, data) => {
    //     try {
    //         await updateNotice(id, data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    render() {
        const {
            children,
        } = this.props;

        return (
            <NoticeContext.Provider
                value={{
                    notices:this.state.notices,
                    deleteNotice: this.deleteNotice,
                    createNotice: this.createNotice,
                    // updateNotice: this.updateNotice,
                }}
            >
                {children}
            </NoticeContext.Provider>
        );
    }
}
