import React, { useContext, useEffect,useState} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { NoticeContext } from "../context/Notices";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 40px",
    width:"100%"
  },
  secondary: {
    fontSize: 12,
    color: "grey",
    paddingBottom: 25,
  },
  link:{color:"#906d31" }
}));
export default function SlideNotice() {
  const classes = useStyles();
  const { notices } = useContext(NoticeContext);
  const [noticias, setNoticias] = useState([]);
  useEffect(() => {
  setNoticias(notices.slice(0,3))
  // eslint-disable-next-line
  }, [notices.length]);
  return (
    <div
      style={{ flexDirection: "column", display: "flex", paddingBottom: 10 }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography
          component="h1"
          variant="h5"
          style={{
            color: "#906d31",
            fontFamily: "Basker",
            textAlign: "center",
            padding: 5,
            marginTop: -17,
          }}
        >
          ____
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          style={{
            color: "#906d31",
            fontFamily: "Basker",
            textAlign: "center",
            padding: "10px 0",
          }}
        >
          GOOD TO KNOW
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          style={{
            color: "#906d31",
            fontFamily: "Basker",
            textAlign: "center",
            padding: 5,
            marginTop: -17,
          }}
        >
          ____
        </Typography>
      </Grid>
      <div>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          //  showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
          showDots={false}
        >
          
          {noticias.map(({ date, message, link }, index) => (
            <React.Fragment key={date}>
            
                <Grid
                  style={{
                    height: 100,
                    display: "flex",
                    justifyContent:"center"
                  }}
                >
                  <List className={classes.root}>
                    <ListItem style={{ textAlign:"center"}}>
                      <ListItemText
                        style={{
                          height: 100,
                          overflow: "scroll",
                          textAlign:"center"
                        }}
                        primary={message}
                        secondary={
                          <Typography className={classes.secondary}>
                            {new Date(date).toDateString()} ,{" "}
                            {new Date(date).getHours()}:
                            {new Date(date).getMinutes() < 10 ? "0" : ""}
                            {new Date(date).getMinutes()}
                            {"  "}
                           {link!==""? <Link
                              className={classes.link}
                              target="_blank"
                              rel="noopener"
                              href={link}
                            >
                              HERE
                            </Link>:null}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
           
            </React.Fragment>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
