import React, { useState, useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// import SendIcon from '@material-ui/icons/Send';
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
// import { db, auth } from '../../services/firebase/setup'
import { NoticeContext } from "../../context/Notices";
import { ContactContext } from "../../context/Contact";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // height:"13vh",
    width: "100%",
    // position:"absolute",
    // bottom:0,
    paddingTop: 10,
    paddingBottom: 20,
  },
  message: {
    // border: '1px solid #ccc',
    // borderRadius: 45,
    padding: "0 20px",
  },
  box: {
    height: "100%",
    paddingLeft: 20,
  },
  send: {
    // height:30,
    // maxWidth:30,
    borderRadius: 90,
    backgroundColor: "#906d31",
    color: "white",
    padding: theme.spacing(1),
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#906d31",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#906d31",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#906d31",
      },
      "&:hover fieldset": {
        borderColor: "#906d31",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#906d31",
      },
    },
  },
})(TextField);

const AddNews = () => {
  const classes = useStyles();

  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const { createNotice } = useContext(NoticeContext);
  const { contacts } = useContext(ContactContext);
  useEffect(() => {
    return () => {
      document.getElementById("app-navbar-button").style.display = "flex";
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const newMessage = {
      message,
      link,
      date: firebase.database.ServerValue.TIMESTAMP,
    };
    // let arr = ['franckgregor93@gmail.com','fgregorio@argency.com.ar']
     let url = process.env.PUBLIC_URL + "/mail/mailer_news.php";
    //  let url = "https://argency.com.ar/mail/mailer_news.php"
   
    // arr.map((elem)=>{
    //   let data = new FormData();
    //   data.append("email", elem);
    //   fetch(url, {
    //     method: "POST",
    //     body: data,
    //   })
    //   return "ok"
    // })
    contacts.map((elem)=>{
      let data = new FormData();
      data.append("email", elem.email);
      fetch(url, {
        method: "POST",
        body: data,
      })
      return "ok"
    })
    createNotice(newMessage);
    setLink("");
    setMessage("");
  };

  const showNavbar = () => {
    document.getElementById("app-navbar-button").style.display = "flex";
  };
  const hiddenNavbar = () => {
    document.getElementById("app-navbar-button").style.display = "none";
  };
  return (
    <Paper square className={classes.paper}>
      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={0} direction="row" className={classes.message}>
        <Grid item xs={12}>
          <CssTextField
            variant="outlined"
            margin="normal"
            name="noticia"
            required
            multiline
            fullWidth
            autoComplete={"off"}
            onFocus={hiddenNavbar}
            onBlur={showNavbar}
            size="small"
            id="noticia"
            label="New"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <CssTextField
            variant="outlined"
            margin="normal"
            name="link"
            required
            fullWidth
            onFocus={hiddenNavbar}
            onBlur={showNavbar}
            size="small"
            id="link"
            label="Link"
            autoComplete={"off"}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" className={classes.box}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.send}
              fullWidth
              disabled={!message.length}
            >
              add new
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* </form> */}
    </Paper>
  );
};

export default AddNews;
