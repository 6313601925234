import { db, storage } from "../services/firebase/setup";
export const loadUser = (uid) => {
  return new Promise((resolve, reject) => {
    db.ref(`/users/${uid}`)
      .once("value")
      .then((snapshot) => {
        const userData = snapshot.val();
        userData.uid=uid;
        if (userData.avatar) {
          // cargar url de avatar
          storage
            .ref()
            .child(`/avatars/${userData.avatar}`)
            .getDownloadURL()
            .then(
              (url) => {
                userData.avatar = url;
                resolve(userData);
              },
              (error) => {
                resolve(userData);
              }
            );
        } else {
          resolve(userData);
        }
      })
      .catch((error) => {
        reject(new Error("Error al leer los datos del usuario"));
      });
  });
};
