import React, { useEffect } from 'react'

const Redir = () => {
    useEffect(() => {
redirect()
    }, []);
    const redirect =()=>{
      
        const a = document.createElement("a");
        a.setAttribute("href", "https://download.errazuriz.cl/sign_in");
        a.setAttribute("id", "iphone");
        a.setAttribute("target", "_blank");
        a.setAttribute("ref", "noreferrer");
        document.body.appendChild(a);
        // document.body.appendChild(a);
        document.querySelector("#iphone").click();
    }
    return (
        <div
        id="redirect"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#656565",
          fontFamily: "Basker",
          width: "100%",
          height: "100vh",
        }}
      >
        {" "}
        <h1>Redirect...</h1>{" "}
      </div>
    )
}

export default Redir
