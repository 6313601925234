import React, {  useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import CustomAvatar from "./CustomAvatar";
// import { loadUser } from "./../utils/dbUtils";
// import { storage } from "../services/firebase/setup";
import { ContactContext } from "../context/Contact";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 5,
    height: "70vh",
  },
  list: {
    marginBottom: theme.spacing(3),
    maxHeight: "100%",
    overflow: "auto",
  },
  containerLogo: {
    position: "relative",
    width: "100%",
    height: "20vh",
    backgroundColor: "black",
    // padding: theme.spacing(2, 0),
    overflow: "hidden",
  },
  logo: {
    width: "50%",
    maxWidth: 245,
    backgroundColor: "transparent",
  },
  luz: {
    position: "absolute",

    boxShadow: " 0 0 200px 60px #906d31",
    margin: "0  auto",
    borderRadius: 16,
    zIndex: 20,
  },
  secondary: {
    fontSize: 12,
    color: "grey",
  },
}));

const Contacts = ({ history }) => {
  const classes = useStyles();

  const { contacts } = useContext(ContactContext);
  const chatDomRef = useRef();

  return (
    <Container style={{ padding: 0, position: "relative" }}>
      <Paper square className={classes.paper}>
        <List className={classes.list} ref={chatDomRef}>
          {contacts.map(({ date, user, name, avatar, message }) => (
            <ListItem
              button
              key={user}
              onClick={() => history.push(`/admin/chat/${user}`)}
            >
              <ListItemAvatar>
                <CustomAvatar name={name} avatar={avatar} size="md" />
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default withRouter(Contacts);
