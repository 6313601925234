import React,{useState} from "react";
import {
  // Link as RouterLink,
  withRouter, useHistory,
} from "react-router-dom";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
// import { HomeContext } from "../../context/Home";
import SlideImage from '../slideImage'
import SlideNotice from "../slideNotice";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    paddingBottom:"10vh"
    // height:"90vh",
  },
  containerLogo: {
    height: "15vh",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#211915",
    textAlign: "center",
    padding: theme.spacing(3, 0),
  },
  logo: {
    width: "50%",
    maxWidth: 245,
  },
  input: {
    borderColor: "#906d31",
  },
  iconos: {
    width: 90,
    marginRight: "4%",
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
  const redirect = () => {
    if (status.isMobile.iOS()) document.querySelector("#iphone").click();
    else history.push("/download");
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <SlideImage />
      </Grid>
      <Grid item xs={12}>
        <SlideNotice />
      </Grid>
      <Grid item container>
        <Grid
          item
          xs={12}
          onClick={redirect}
          style={{
            paddingLeft: "10%",
            backgroundColor: "#906d31",
            cursor: "pointer",
            height: 95,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            className={classes.iconos}
            alt="icon download"
            src={require("../../assets/icono_downloads.svg")}
          />
          <Typography
            style={{ fontSize: 20, color: "#ffffff", fontFamily: "Basker" }}
          >
            DOWNLOAD
          </Typography>
        </Grid>
        <Link
            id={"iphone"}
            style={{ height: 1, width: 1,visibility:"hidden" }}
            target="_blank"
            rel="noopener"
            href="https://download.errazuriz.cl"
            >{"."}</Link>
      </Grid>
    </Grid>
  );
};
export default withRouter(Home);
