import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import Alert from "./Alert";

const MyLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#906d31",
    color: "white  ",
    "&:hover": {
      backgroundColor:"#906d31"
    },
    "&:after": {
      backgroundColor:"#906d31"
    },
    "&:before": {
      backgroundColor:"#906d31"
    }
  },
  containerLogo: {
    width: "100%",
    backgroundColor: "#211915",
    textAlign: "center",
    padding: theme.spacing(3, 0),
  },
  logo: {
    width: "50%",
    maxWidth: 245,
  },
}));
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#906d31",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#906d31",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#906d31",
      },
      "&:hover fieldset": {
        borderColor: "#906d31",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#906d31",
      },
    },
  },
})(TextField);
const SignUp = (props) => {
  const classes = useStyles();

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    avatar: "",
  });

  const [alertMessage, setAlertMessage] = useState(null);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setAlertMessage(null);

    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((response) => {
        // guardar los datos del usuario
        delete user.password;
        firebase.database().ref(`/users/${response.user.uid}`).set(user);
        //alert('Bienvenido a Chat App');
        setAlertMessage({
          type: "success",
          message: "Bienvenido a Chat App",
        });
        // props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
        //alert(error.message);
        setAlertMessage({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <React.Fragment>
      <Grid className={classes.containerLogo}>
        <img
          alt="logo"
          className={classes.logo}
          src={require("../assets/errazuriz.svg")}
        />
      </Grid>
      <Container component="main" maxWidth="xs" style={{paddingBottom:"10vh"}}>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{ color: "#656565", fontFamily: "Basker" }}
          >
            SIGN IN
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <CssTextField
                  autoComplete="fname"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  value={user.name}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                id="avatar"
                label="URL avatar"
                name="avatar"
                value={user.avatar}
                onChange={handleChange}
              />
            </Grid> */}
              <Grid item xs={12}>
                <CssTextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={user.password}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
             SIGN IN
            </Button>
            <Grid container justify="center">
              <Grid item>
                <Link
                  to="/login"
                  style={{ color: "#906d31" }}
                  component={MyLink}
                  variant="body2"
                >
                  {"Do you have an account? Sign in here"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        {alertMessage && (
          <Alert
            type={alertMessage.type}
            message={alertMessage.message}
            autoclose={5000}
          />
        )}
      </Container>
    </React.Fragment>
  );
};
export default withRouter(SignUp);
