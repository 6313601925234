import React, { useState,useContext,useEffect } from 'react';
import {useParams} from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { AuthContext }   from '../../context/Auth'
import SendIcon from '@material-ui/icons/Send';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
// import { ChatContext } from '../../context/Chats';
import{auth} from '../../services/firebase/setup'
import { ChatAdminContext } from '../../context/ChatAdmin';
import { ContactContext } from '../../context/Contact';
// import app from '../../config/firebaseConfig'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    // height:"13vh",
    width:"100%",
    // position:"absolute",
    bottom:0,
    paddingTop: 10,
    paddingBottom: 20,
    marginBottom:"10vh"
  },
  message: {
    // border: '1px solid #ccc',
    // borderRadius: 45,
    padding: '0 20px',
  },
  box: {
    height: '100%',
    paddingLeft: 20,
  },
  send:{
    // height:30,
    // maxWidth:30,
    borderRadius: 90  ,
    backgroundColor:"#906d31",
    color:"white",
    padding:theme.spacing(1)
  }
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#906d31',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#906d31',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#906d31',
      },
      '&:hover fieldset': {
        borderColor: '#906d31',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#906d31',
      },
    },
  },
})(TextField);

const NewMessage = () => {
  const classes = useStyles();
  const {uid} = useParams()
  const [message, setMessage] = useState('');
  const {user} = useContext(AuthContext);
  const {createChat} = useContext(ChatAdminContext);
  const {contacts} = useContext(ContactContext)
  useEffect(() => {
  
    return () => {
      document.getElementById('app-navbar-button').style.display = "flex";
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    const { currentUser } = auth;
    console.log(currentUser)
    if (!currentUser) return;

    const newMessage = {
      user: uid,
      message,
      date: firebase.database.ServerValue.TIMESTAMP,
      name:user.name,
      avatar:user.avatar,
      type:'admin'
    };
    createChat(newMessage)
    setMessage('');
    let mailer = contacts.filter(elem => elem.user===uid)
    const data = new FormData();
    data.append("name", "Administrador");
    data.append("email", mailer[0].email );
    data.append("type", "admin");
    let url = process.env.PUBLIC_URL +'/mail/mailer_chat.php';
    // let url = 'https://argency.com.ar/mail/mailer_chat.php';
    // console.log(data);
    fetch(url, {
      method: "POST",
      body: data
    })
  };

  const showNavbar =()=>{
    setTimeout(() => {
      document.getElementById('paper-msj-new').style.marginBottom = "10vh";
      document.getElementById('paper-msj-body').style.height = "56vh";
      document.getElementById('app-navbar-button').style.display = "flex";
      scrollBottom()
    }, 700);
  
  }
  const hiddenNavbar =()=>{
    document.getElementById('paper-msj-new').style.marginBottom = "0vh";
    document.getElementById('paper-msj-body').style.height = "65vh";
    document.getElementById('app-navbar-button').style.display = "none";
  }
  const scrollBottom = (event) => {
    const anchor = document.querySelector('#back-to-bottom-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Paper id={'paper-msj-new'}square className={classes.paper}>
      {/* <form onSubmit={handleSubmit}> */}
        <Grid container spacing={0} direction="row" className={classes.message}>
          <Grid item xs={9}>
              <CssTextField
                 variant="outlined"
                margin="normal"
                name="message"
                required
                onFocus={hiddenNavbar}
                onBlur={showNavbar}
                fullWidth
                autoComplete={"off"}
                id="message"
                label="Message"
          
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" className={classes.box}>
              <Button
              onClick={handleSubmit}
                variant="contained"
                className={classes.send}
                fullWidth
                disabled={!message.length}
              >
                <SendIcon style={{width:20,height:20}}/>
              </Button>
            </Box>
          </Grid>
        </Grid>
      {/* </form> */}
    </Paper>
  );
};

export default NewMessage;


