import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Link from "@material-ui/core/Link";
import { 
  // Link as RouterLink, 
  withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';

// const MyLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  root:{
    flexGrow:1,
    height:"90vh"
  },
  containerLogo:{
    width:"100%",
    height:"15vh",
    backgroundColor:"#211915",
    textAlign:"center",
    padding:theme.spacing(3,0)
  },
  logo:{
    width:"50%",
    maxWidth:245
  },
  containerDownload:{
    // height:"75vh",
    overflow:"hidden"
  },
  iframe:{
    borderWidth:0,
    width:"100%",
    height:"90vh",
    marginTop:-53
  },
  input:{
    borderColor:"#906d31",
  }
}));

const Download = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
   
    <Grid item xs={12} className={classes.containerDownload}>
      <CssBaseline />
      <iframe id="inlineFrameExample"
        className={classes.iframe}
        title="Downloads"
        referrerPolicy="no-referrer"
        src="https://download.errazuriz.cl/sign_in">
    </iframe>
     {/* <Link
              target="_blank"
              rel="noopener"
              href="https://download.errazuriz.cl/sign_in"
            >
           dowload
            </Link> */}
    </Grid >
    </Grid>
  );
};
export default withRouter(Download);
