import React, {
  //  useState,
    useEffect, useRef,useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
// import NewMessage from "./NewMessage";
import CustomAvatar from "../CustomAvatar";
// import { loadUser } from "../../utils/dbUtils";
// import { db } from "../../services/firebase/setup";
// import { ChatContext } from "../../context/Chats";
import { ChatAdminContext } from "../../context/ChatAdmin";
// import { AuthContext } from "../../context/Auth";
// import { storage } from "../../services/firebase/setup";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 5,
    height: "70vh",
  },
  list: {
    marginBottom: theme.spacing(3),
    maxHeight: "100%",
    overflow: "auto",
  },
  containerLogo: {
    position: "relative",
    width: "100%",
    height: "20vh",
    backgroundColor: "black",
    // padding: theme.spacing(2, 0),
    overflow: "hidden",
  },
  logo: {
    width: "50%",
    maxWidth: 245,
    backgroundColor: "transparent",
  },
  luz: {
    position: "absolute",

    boxShadow: " 0 0 200px 60px #906d31",
    margin: "0  auto",
    borderRadius: 16,
    zIndex: 20,
  },
  secondary: {
    fontSize: 12,
    color: "grey",
  },
}));

const Chat = ({ history }) => {
  const classes = useStyles();
  // const [messages, setMessages] = useState([]);
  const {chats} = useContext(ChatAdminContext);
  // const {user} = useContext(AuthContext);
  const chatDomRef = useRef();

useEffect(() => {
  if (chatDomRef.current) {
    chatDomRef.current.scrollTop = 0;
  }
  // console.log(chats)
  // debugger;
  // chats.map(async elem=>{
  //     elem.user=await loadUser(elem.user)
  // })
}, []);
// const load =(avatar)=>{
//   return new Promise((resolve, reject) => {
//     if (avatar) {
//       // cargar url de avatar
//       storage
//         .ref()
//         .child(`/avatars/${avatar}`)
//         .getDownloadURL()
//         .then(
//           (url) => {
            
//             resolve(url);
//           },
//           (error) => {
//             resolve("");
//           }
//         );
//     } else {
//       resolve("");
//     }
//   })
// }

  return (
    <Container style={{ padding: 0,position:"relative"}}>
      <Paper square className={classes.paper}>
        <List className={classes.list} ref={chatDomRef}>
          {chats.map( ({ date,user, name, avatar, message }) => (
            <ListItem button key={date} onClick={()=>history.push(`/admin/chat/${user}`)}>
              <ListItemAvatar>
              {/* {console.log( load(avatar))} */}
                <CustomAvatar name={name} avatar={ avatar} size="md" />

              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={
                  <Typography className={classes.secondary}>
                    {new Date(date).toDateString()} ,{" "}
                    {new Date(date).getHours()}:
                    {new Date(date).getMinutes() < 10 ? "0" : ""}
                    {new Date(date).getMinutes()}
                  
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
  
    </Container>
  );
};

export default withRouter(Chat);
