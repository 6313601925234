import { db } from './setup';

export async function createNotice(data) {
    return   db.ref('/news').push(data)
    .catch(error => {
    //   console.log(error);
      alert(error.message);
    })
}
export async function deleteImageHome(uid) {
  return db.ref(`home/${uid}`).remove()
    .then(function() {
      console.log("Remove succeeded.")
    })
    .catch(function(error) {
      console.log("Remove failed: " + error.message)
    });
}

export async function deleteNotice(uid) {
    return db.ref(`news/${uid}`).remove()
      .then(function() {
        console.log("Remove succeeded.")
      })
      .catch(function(error) {
        console.log("Remove failed: " + error.message)
      });
}

export async function createChat(data) {
    return   db.ref('/chat').push(data)
    .catch(error => {
    //   console.log(error);
      alert(error.message);
    })
}
// export async function updateExpense(id, data) {
//     return await db
//         .collection('expenses')
//         .doc(id)
//         .update(data);
// }
