import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  sm: {
    width: 24,
    height: 24,
    // margin:"5px auto",
    fontSize:13
  },
  md: {
    width: 42,
    height: 42,
    
  },
  lg: {
    width: 64,
    height: 64
  },
  xl: {
    width: 84,
    height: 84,
    fontSize:40
  },
  bg: {
    backgroundColor: '#906d31',
    margin:"5px auto"
  }
});

const CustomAvatar = ({ name, avatar, size }) => {
  const classes = useStyles();

  return (
    <Avatar
      alt={name}
      src={avatar}
      className={clsx(classes[size], !avatar ? classes.bg : null)}
    >
      {!avatar ? name.charAt(0).toUpperCase() : null}
    </Avatar>
  );
};

CustomAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']).isRequired
};

export default CustomAvatar;
