import React, {  useState,useEffect, useRef,useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
// import Grid from "@material-ui/core/Grid";
import NewMessage from "./NewMessage";
import CustomAvatar from "../CustomAvatar";
import { loadUser } from "../../utils/dbUtils";
// import { db } from "../../services/firebase/setup";
// import { ChatContext } from "../../context/Chats";
import { db } from "../../services/firebase";
import { AuthContext } from "../../context/Auth";
// import { db } from "../../services/firebase";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 5,
    height: "55vh",
  },
  list: {
    marginBottom: theme.spacing(3),
    maxHeight: "100%",
    overflow: "auto",
  },

  listItem:{
    backgroundColor:"#f6f1eb"
  }
}));

const Chat = ({ history }) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  // const {chats} = useContext(ChatContext);
  const {user} = useContext(AuthContext);
  const chatDomRef = useRef();

  // useEffect(() => {
  //   scrollBottom()
  // }, [chats.length]);
  const addMessage = async (message) => {
    let user={}
    if(message.type==="admin")
    //  user = await loadUser("4v4y9zoBW1Puve5ZMskQXvM4bs43")
     user = await loadUser("0HlzCrc7xqccbpBrqweCTEIxdEf1")
     else
     user = await loadUser(message.user)
    message.name=user.name
    message.avatar=user.avatar
    messages.push(message);
    setMessages([...messages.sort((a, b) => a.date - b.date)]);
    scrollBottom();
    // if (chatDomRef.current) {
    //   chatDomRef.current.scrollTop = chatDomRef.current.scrollHeight;
    // }
    // console.log(chatDomRef.current)
    // debugger;
  };
  const scrollBottom = (event) => {
    const anchor = document.querySelector('#back-to-bottom-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  useEffect(() => {
 
    const chatRef = db.ref("/chat");
    chatRef
      .orderByChild("user")
      .equalTo(user.uid)
      .on(
        "child_added",
        (snapshot) => {
          scrollBottom();
          // nuevo mensaje
          const messageItem = snapshot.val();
          // leer los datos del usuario

          addMessage(messageItem);
        },
        (error) => {
          console.log(error);
          if (error.message.includes("permission_denied")) {
            history.push("/login");
          }
        }
      );
      // eslint-disable-next-line
  }, []);
  return (
    <Container style={{ padding: 0,position:"relative"}}>
     
      <Paper id={"paper-msj-body"} square className={classes.paper}>
        <List className={classes.list} ref={chatDomRef}>
          {messages.map(({ date, message, name,type,avatar}) => (
             
            <ListItem button key={date} className={type==="admin"?classes.listItem:null}>
              <ListItemAvatar>
            
                <CustomAvatar name={name} avatar={avatar} size="md" />
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={message}
              />
            </ListItem>
          ))}
          <div id={"back-to-bottom-anchor"}></div>
        </List>
      </Paper>
      <NewMessage />
    </Container>
  );
};

export default withRouter(Chat);
