import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";
import { DialogActions, DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, withStyles } from "@material-ui/core/styles";


function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );
  return tmpCanvas;
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0 0 25px 0",
  },
  button: {
    padding: 8,
    fontSize: 10,
    color: "#906d31",
    borderRadius: 15,
    backgroundColor: "#f6f1eb",
    margin: 3,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 1.25,
    "&:hover": {
      backgroundColor: "#f6f1eb",
      boxShadow: "none",
    },
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: 1.38,
    color: "#4a4a4a",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: 1.38,
    color: "#4a4a4a",
  },
  containerTitle: {
    padding: theme.spacing(2),
  },
  form: {
    width: "100%",
    maxWidth: 800,
    padding: "10px 0",
  },
  input: {
    backgroundColor: "white",
    borderRadius: 15,
  },
  submit: {
    padding: 8,
    fontSize: 14,
    color: "white",
    borderRadius: 20,
    backgroundColor: "#50e3c2",
    margin: 3,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 1.25,
    "&:hover": {
      backgroundColor: "#50e3c2",
      boxShadow: "none",
    },
  },
}));
const Modal = withStyles({
  root: {
    "& .MuiPaper-rounded": {
      borderRadius: 15,
    },
  },
})(Dialog);

const UploadImage = ({ setImagen }) => {
  const classes = useStyles();
  const [upImg, setUpImg] = useState(null);
  // const [imgBase64, setImgBase64] = useState(null);
  const imgRef = useRef(null);
  const [open, setOpen] = useState(false);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  async function generateDownload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
      return;
    }

    const dpr = window.devicePixelRatio || 1;
    const canvas =
      dpr !== 1
        ? getResizedCanvas(previewCanvas, crop.width, crop.height)
        : previewCanvas;

    let dataURL = canvas.toDataURL();
    // setImgBase64(dataURL);
    // setImagen(dataURL);

    let file = dataURLtoFile(dataURL, "new_avatar.png");
    setImagen(file);
    handleToggle();
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const dpr = window.devicePixelRatio || 1;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * dpr;
    canvas.height = crop.height * dpr;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * dpr,
      crop.height * dpr
    );
  }, [completedCrop]);

  return (
    <React.Fragment>
     
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<PhotoCameraIcon />}
          onClick={handleToggle}
        >
        Profile picture
        </Button>
     

      <Modal
        open={open}
        onClose={handleToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <div style={{ padding: "15px 0" }}>
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          {upImg == null ? (
            <div
              style={{
                width: 260,
                height: 260,
                backgroundColor: "#e6eaeb",
                margin: "auto",
              }}
            ></div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "auto",
                overflow:"hidden",
                backgroundColor: "#e6eaeb",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                margin: "auto",
              }}
            >
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
            </div>
          )}
          <div style={{ display: "none" }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: completedCrop?.width ?? 0,
                height: completedCrop?.height ?? 0,
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() =>
                generateDownload(previewCanvasRef.current, completedCrop)
              }
            >
              Recortar
            </Button>
          </div>
        </DialogContent>
        <DialogActions />
      </Modal>
    </React.Fragment>
  );
};

export default UploadImage;
