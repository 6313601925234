import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import Header from "../components/layout/Header";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const {  user,isLoggedIn } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn? (
          user.type === undefined ? (
            <Header>
              <RouteComponent {...routeProps} />
            </Header>
          ) : (
            <Redirect to={"/admin"} />
          )
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
