import React, { useContext } from "react";
import { AuthContext } from "../context/Auth";

const Root = ({ children }) => {
  const { authReady } = useContext(AuthContext);

  if (!authReady) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#656565",
          fontFamily: "Basker",
          width: "100%",
          height: "100vh",
        }}
      >
        {" "}
        <h1>Loading...</h1>{" "}
      </div>
    );
  }

  return children;
};

export default Root;
