import React, { useState, useEffect, useRef, useContext } from "react";
// import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from "./Modal";
import AddNews from "./AddNews";
import { NoticeContext } from "../../context/Notices";
import {AuthContext} from '../../context/Auth'

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: "10vh",
    minHeight: "55vh",
  },
  list: {
    marginBottom: theme.spacing(3),
    maxHeight: "100%",
    overflow: "auto",
  },
  containerLogo: {
    position: "relative",
    width: "100%",
    height: "20vh",
    backgroundColor: "black",
    // padding: theme.spacing(2, 0),
    overflow: "hidden",
  },
  logo: {
    width: "50%",
    maxWidth: 245,
    backgroundColor: "transparent",
  },
  luz: {
    position: "absolute",

    boxShadow: " 0 0 200px 60px #906d31",
    margin: "0  auto",
    borderRadius: 16,
    zIndex: 20,
  },
  secondary: {
    fontSize: 12,
    color: "grey",
    paddingBottom: 25,
  },
  link:{color:"#906d31" }
}));

const News = () => {
  const classes = useStyles();
  // const [messages, setMessages] = useState([]);
  const { notices } = useContext(NoticeContext);
  const {user} = useContext(AuthContext)
  const [open, setOpen] = useState(false);
  const [tempUid, setTempUid] = useState(null);
  const chatDomRef = useRef();
 
  useEffect(() => {
    if (chatDomRef.current) {
      chatDomRef.current.scrollTop = 0;
    }
  }, []);
  // const removeMessage = (uid)=>{
  //   let newNoticia = messages.filter(elem=> elem.uid!==uid)
  //   setMessages(newNoticia)
  // }
  const toggleOpen =(uid)=>{
    setOpen(true)
    setTempUid(uid)
  }
  return (
    <Container style={{ padding: 0, position: "relative" }}>
     
      <Typography
        component="h1"
        variant="h5"
        style={{
          // height:"7vh",
          padding: "30px 0 15px 0",
          textAlign: "center",
          color: "#656565",
          backgroundColor: "white",
          fontFamily: "Basker",
        }}
      >
      GOOD TO KNOW
      </Typography>
      {user.type===undefined?null:  <AddNews />}
      <Paper square className={classes.paper}>
        <List className={classes.list} ref={chatDomRef}>
        
          {notices.map(({ date, message, uid,link }, index) => (
            <React.Fragment key={date}>
             
              <ListItem button>
                <ListItemText
                  primary={message}
                  secondary={
                    <Typography className={classes.secondary}>
                      {new Date(date).toDateString()} ,{" "}
                      {new Date(date).getHours()}:
                      {new Date(date).getMinutes() < 10 ? "0" : ""}
                      {new Date(date).getMinutes()}
                      {"  "}
                     {link!==""? <Link
                        className={classes.link}
                        target="_blank"
                        rel="noopener"
                        href={link}
                      >
                        HERE
                      </Link>:null}
                    </Typography>
                  }
                />

                {user.type===undefined?null: <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={()=>toggleOpen(uid)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>}
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Paper>
      {user.type===undefined?null:  <Modal open={open} toggle={setOpen} uid={tempUid} 
      // resetUid={removeMessage}
      />}
   
    </Container>
  );
};

export default News;
