import React from 'react';

import {
    watchUserChanges,
    watchChatsAdmin,
    createChat,
    // deleteNotice,
    // updateNotice,
} from '../services/firebase';


export const ChatAdminContext = React.createContext({});

export const ChatAdminContextConsumer = ChatAdminContext.Consumer;

export class ChatAdminContextProvider extends React.Component {
    state = {
        chats: [],
    }

    componentDidMount() {
            
        this.userWatcherUnsub = watchUserChanges((user) => {
            if (user && !this.expenseWatcherUnsub) {
                this.expenseWatcherUnsub = watchChatsAdmin((chats) => {
                    // let uid = GetUserUid()
                    // let filterChat = chats.filter(elem=> elem.user===uid)
                    this.setState({ chats });
                   
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.expenseWatcherUnsub) {
            this.expenseWatcherUnsub();
        }
    }

    // deleteNotice = async (id) => {
    //     try {
    //         await deleteNotice(id);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    createChat = async (data) => {
        try {
            await createChat(data);
        } catch (error) {
            console.log(error);
        }
    }
    // resetChat=()=>{
    //     this.setState({chats:[]})
    // }
    // updateExpense = async (id, data) => {
    //     try {
    //         await updateNotice(id, data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    render() {
        const {
            children,
        } = this.props;

        return (
            <ChatAdminContext.Provider
                value={{
                    chats:this.state.chats,
                    // deleteNotice: this.deleteNotice,
                    createChat: this.createChat,
                    // resetChat:this.resetChat
                    // updateNotice: this.updateNotice,
                }}
            >
                {children}
            </ChatAdminContext.Provider>
        );
    }
}
