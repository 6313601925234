import React, { useState, useContext } from "react";
import {
  // Link as RouterLink,
  withRouter,
} from "react-router-dom";
// import Link from "@material-ui/core/Link";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from "@material-ui/core/styles";
import Alert from "../Alert";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import {  storage, db } from "../../services/firebase/setup";
import { HomeContext } from "../../context/Home";
import Modal from "./Modal";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    paddingBottom: "10vh",
    // height:"90vh",
  },
  containerLogo: {
    height: "15vh",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#211915",
    textAlign: "center",
    padding: theme.spacing(3, 0),
  },
  logo: {
    width: "50%",
    maxWidth: 245,
  },
  input: {
    borderColor: "#906d31",
  },
  iconos: {
    width: 90,
    marginRight: "4%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#906d31",
    color: "white  ",
    "&:hover": {
      backgroundColor: "#906d31",
    },
    "&:after": {
      backgroundColor: "#906d31",
    },
    "&:before": {
      backgroundColor: "#906d31",
    },
  },
  fecha:{
    color: "#906d31",
    textAlign:"center"
  },
    file: {
    // marginTop: 20,
    // marginBottom: 10,
    // marginLeft: "-1px",
    margin: "10px auto",
    backgroundColor: "#f6f1eb",
    color: "#906d31",
  },
  button:{
    position:"absolute",
    backgroundColor: "#f6f1eb",
    color: "#906d31",
    right:20,
    top:20
  }
}));

const Home = (props) => {
  const classes = useStyles();

  const [image, setImage] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [tempUid, setTempUid] = useState(null);
  const {home} = useContext(HomeContext)
 
  const handleImage = (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setImage({
      type: file.type.split("/")[1],
      file,
    });
  };
  const handleSubmit =  (e) => {
    e.preventDefault();

    setAlertMessage(null);
    // const { currentUser } = auth;

    if (image) {
      let nombre = new Date().getTime();
     
      let home = `${nombre}.${image.type}`;
      storage
        .ref(`/home/${home}`)
        .put(image.file)
        .then(() => {
          // storage
          //   .ref()
          //   .child(`/avatars/${home}`)
          //   .getDownloadURL()
          //   .then((url) => {
          //     setUser({ ...user, avatar: url });
          //   });
          setImage(null);
          db.ref("/home")
          .push({ home: `${nombre}.${image.type}`,date:firebase.database.ServerValue.TIMESTAMP })
          .then((response) => {
            setAlertMessage({ type: "success", text: "Correcto" });
          })
          .catch((error) => {
            setAlertMessage({ type: "error", text: error.message });
          });
          console.log("imagen subida");
        });
   
    }
  };

  const toggleOpen = (uid) => {
    setOpen(true);
    setTempUid(uid);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} style={{ textAlign: "center", padding: "15px 0" }}>
        <Typography
          component="h1"
          variant="h5"
          style={{ color: "#656565", fontFamily: "Basker" }}
        >
          CARROUSEL
        </Typography>
      </Grid>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              type="file"
              accept="image/*"
              id="avatar"
              name="avatar"
              style={{ width: "1px" }}
              onChange={handleImage}
            />
            <label
              htmlFor="avatar"
              style={{ display: "inline-grid", color: "#906d31" }}
            >
              <Button
                className={classes.file}
                variant="contained"
                component="span"
              >
               Select picture
              </Button>
              {image && (
                <span className={classes.filename}>{image.file.name}</span>
              )}
            </label>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          SAVE
        </Button>
      </form>
      <Grid container>
 
          {home.map(({image,date,uid})=>(
            <Grid item xs={12} key={date} style={{padding:10,paddingBottom:30,position:"relative"}}>
                  <Button
                    variant="contained"
                    onClick={()=>toggleOpen(uid)}
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                <img style={{width:"100%"}}alt={`${date}`} src={image}/>
                    <Typography className={classes.fecha}>
                      {new Date(date).toDateString()} ,{" "}
                      {new Date(date).getHours()}:
                      {new Date(date).getMinutes() < 10 ? "0" : ""}
                      {new Date(date).getMinutes()}
                    </Typography>
                  
            </Grid>
          ))}
             <Modal
                open={open}
                toggle={setOpen}
                uid={tempUid}
              />
      </Grid>
      {alertMessage && (
        <Alert
          type={alertMessage.type}
          message={alertMessage.text}
          autoclose={5000}
        />
      )}
    </Grid>
  );
};
export default withRouter(Home);
