import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { AuthContext } from "../../context/Auth";
import { auth } from "../../services/firebase/setup";
// import { ChatContext } from "../../context/Chats";
const MyLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));
const useStyles = makeStyles((theme) => ({
  button: {
    color: "black",
    padding: 15,
  },
  profile: {
    color: "#906d31",
  },
  iconInactive: { color: "#656565" },
}));

const User = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { user } = useContext(AuthContext);
  // const { resetChat } = useContext(ChatContext);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    // resetChat()
    auth.signOut();
  };
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        className={classes.button}
      >
        <PermIdentityIcon className={classes.iconInactive} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem disabled>{user.name}</MenuItem>
        <MenuItem>
          <Link
            to={user.type === undefined ? "/profile" : "/admin/profile"}
            component={MyLink}
            variant="body2"
            onClick={closeMenu}
            className={classes.profile}
          >
            {"Edit profile"}
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Exit</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(User);
