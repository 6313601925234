import React from "react";
import { BrowserRouter } from "react-router-dom";
import Root from "./utils/root";
import Routes from "./Routes";
import { AuthProvider } from "./context/Auth";
import { NoticeContextProvider } from "./context/Notices";
// import { ChatContextProvider } from "./context/Chats";
import { HomeContextProvider } from "./context/Home";
import { ContactContextProvider } from "./context/Contact";
import { ChatAdminContextProvider } from "./context/ChatAdmin";
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NoticeContextProvider>
          <ContactContextProvider>
            <ChatAdminContextProvider>
              {/* <ChatContextProvider> */}
                <HomeContextProvider>
                  <Root>
                    <Routes />
                  </Root>
                </HomeContextProvider>
              {/* </ChatContextProvider> */}
            </ChatAdminContextProvider>
          </ContactContextProvider>
        </NoticeContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
