import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth";


const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn? (
        
            <Redirect to={"/"} />
          
        ) : (
            <RouteComponent {...routeProps} />
        )
      }
    />
  );
};

export default PrivateRoute;

