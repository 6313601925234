import React from 'react';
import {
    watchUserChanges,
    watchContacts,
    // createNotice,
    // deleteNotice,
    // updateNotice,
} from '../services/firebase';

export const ContactContext = React.createContext({});

export const ContactContextConsumer = ContactContext.Consumer;

export class ContactContextProvider extends React.Component {
    state = {
        contacts: [],
    }

    componentDidMount() {
        this.userWatcherUnsub = watchUserChanges((user) => {
            if (user && !this.expenseWatcherUnsub) {
                this.expenseWatcherUnsub = watchContacts((contacts) => {
                    this.setState({ contacts });
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.expenseWatcherUnsub) {
            this.expenseWatcherUnsub();
        }
    }

    // deleteNotice = async (id) => {
    //     try {
    //         await deleteNotice(id);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // createNotice = async (data) => {
    //     try {
    //         await createNotice(data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // updateExpense = async (id, data) => {
    //     try {
    //         await updateNotice(id, data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    render() {
        const {
            children,
        } = this.props;

        return (
            <ContactContext.Provider
                value={{
                    contacts:this.state.contacts,
                    // deleteNotice: this.deleteNotice,
                    // createNotice: this.createNotice,
                    // updateNotice: this.updateNotice,
                }}
            >
                {children}
            </ContactContext.Provider>
        );
    }
}
