import React, { useState, useCallback, useContext } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink, withRouter, Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {auth} from "../services/firebase/setup";
import { AuthContext } from "../context/Auth.js";
import Alert from "./Alert";

const MyLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  containerLogo: {
    width: "100%",
    backgroundColor: "#211915",
    textAlign: "center",
    padding: theme.spacing(3, 0),
  },
  logo: {
    width: "50%",
    maxWidth: 245,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#906d31",
    color: "white  ",
    "&:hover": {
      backgroundColor:"#906d31"
    },
    "&:after": {
      backgroundColor:"#906d31"
    },
    "&:before": {
      backgroundColor:"#906d31"
    }
  },
  input: {
    borderColor: "#906d31",
  },
}));
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#906d31",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#906d31",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#906d31",
      },
      "&:hover fieldset": {
        borderColor: "#906d31",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#906d31",
      },
    },
  },
})(TextField);
const Login = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setErrorMessage("");
      const { email, password } = event.target.elements;
      try {
        await auth
          .signInWithEmailAndPassword(email.value, password.value);
        // props.history.push("/");
      } catch (error) {
        setErrorMessage(error.message);
      }
    },
    []
  );
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }
  return (
    <React.Fragment>
      <Grid className={classes.containerLogo}>
        <img
          alt="logo"
          className={classes.logo}
          src={require("../assets/errazuriz.svg")}
        />
      </Grid>
      <Container component="main" maxWidth="xs" style={{paddingBottom:"10vh"}}>
        <CssBaseline />

        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{ color: "#656565", fontFamily: "Basker" }}
          >
            LOGIN
          </Typography>
          <form className={classes.form} onSubmit={handleLogin}>
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              className={classes.input}
              autoFocus
              defaultValue={user.email}
              onChange={handleChange}
            />
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              className={classes.input}
              autoComplete="current-password"
              defaultValue={user.password}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              LOG IN
            </Button>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Link
                  to="/signup"
                  style={{ color: "#906d31" }}
                  component={MyLink}
                  variant="body2"
                >
                  {"Create Account"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        {errorMessage && (
          <Alert type="error" message={errorMessage} autoclose={5000} />
        )}
      </Container>
    </React.Fragment>
  );
};
export default withRouter(Login);
