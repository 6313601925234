import firebase from 'firebase';
import config from '../../config/firebaseConfig';

const firebaseConfig = {
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
};



firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage()
// db.settings({
//     timestampsInSnapshots: true,
// });
