import { auth, db, storage } from "./setup";
import { loadUser } from "../../utils/dbUtils";
export function watchUserChanges(callback) {
  const unsub = auth.onAuthStateChanged(async (response) => {
    if (response) {
      let user = await loadUser(response.uid);

      callback(user);
    } else {
      callback(null);
    }
  });

  return unsub;
}

export function watchNotices(callback) {
  const unsub = db.ref("/news");
  unsub.on("value", (snapshot) => {
    const docs = [];
    // nuevo mensaje
    const noticeItem = snapshot.val();

    for (const prop in noticeItem) {
      docs.push({
        date: noticeItem[prop].date,
        message: noticeItem[prop].message,
        link: noticeItem[prop].link,
        uid: prop,
      });
    }
    let orderDocs = docs.sort(function (a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    // leer los datos del usuario
    callback(orderDocs);
  });

  return unsub;
}

export function watchContacts(callback) {
  const { currentUser } = auth;
  const unsub = db.ref("/users");
  unsub.on("value", (snapshot) => {
    const docs = [];
    // nuevo mensaje
    const contactItem = snapshot.val();

    for (const prop in contactItem) {
      if (currentUser.uid !== prop)
        docs.push({
          name: contactItem[prop].name,
          email: contactItem[prop].email,
          avatar: "",
          user: prop,
        });
    }

    let listOrderName = docs.sort(function (a, b) {
      a = a.name;
      b = b.name;
      return a > b ? 1 : a < b ? -1 : 0;
    });

    callback(listOrderName);
  });

  return unsub;
}
export function watchChats(callback) {
  const { currentUser } = auth;

  const unsub = db.ref("/chat");
  unsub.on("value", async (snapshot) => {
    const docs = [];
    // // nuevo mensaje
    const noticeItem = await snapshot.val();

    if (currentUser.email === "info@errazuriz.cl") {
      for (const prop in noticeItem) {
        docs.push({
          date: noticeItem[prop].date,
          message: noticeItem[prop].message,
          user: noticeItem[prop].user,
          avatar: noticeItem[prop].avatar,
          name: noticeItem[prop].name,
          type: noticeItem[prop].type,
        });
      }
      let arrayFilter = docs.filter((el) => el.type !== "admin");
      let arrayOldOrder = arrayFilter.sort(function (a, b) {
        a = new Date(a.date);
        b = new Date(b.date);
        return a > b ? -1 : a < b ? 1 : 0;
      });

      let orderDocs = [];
      // eslint-disable-next-line
      arrayOldOrder.map((elem) => {
        let condicional = orderDocs.find((el) => el.user === elem.user);

        if (!condicional) {
          let array = arrayOldOrder.filter((el) => el.user === elem.user);

          orderDocs.push(array[0]);
        }
      });
      orderDocs.map(async (elem) => {
        let user = await loadUser(elem.user);

        elem.avatar = user.avatar;
        elem.name = user.name;
      });

      callback(orderDocs);
    } else {
      let avatarAdmin = "";
      let nameAdmin = "";
      let avatarUser = "";
      let nameUser = "";
      for (const prop in noticeItem) {
        if (currentUser.uid === noticeItem[prop].user) {
          if (noticeItem[prop].type !== "admin") {
            if (nameUser === "") {
              let user = await loadUser(noticeItem[prop].user);

              nameUser = user.name;
              avatarUser = user.avatar;
            }
            docs.push({
              date: noticeItem[prop].date,
              message: noticeItem[prop].message,
              user: noticeItem[prop].user,
              avatar: avatarUser,
              name: nameUser,
              type: noticeItem[prop].type,
            });
          } else {
            if (nameAdmin === "") {
              let user = await loadUser("4v4y9zoBW1Puve5ZMskQXvM4bs43");

              nameAdmin = user.name;
              avatarAdmin = user.avatar;
            }
            docs.push({
              date: noticeItem[prop].date,
              message: noticeItem[prop].message,
              user: noticeItem[prop].user,
              avatar: avatarAdmin,
              name: nameAdmin,
              type: noticeItem[prop].type,
            });
          }
        }
      }
      let orderDocs = docs.sort(function (a, b) {
        a = new Date(a.date);
        b = new Date(b.date);
        return a < b ? -1 : a > b ? 1 : 0;
      });
      callback(orderDocs);
    }
  });

  return unsub;
}
export function watchChatsAdmin(callback) {
  const unsub = db.ref("/chat");
  unsub.on("value", async (snapshot) => {
    const docs = [];

    const noticeItem = await snapshot.val();

    for (const prop in noticeItem) {
      docs.push({
        date: noticeItem[prop].date,
        message: noticeItem[prop].message,
        user: noticeItem[prop].user,
        avatar: noticeItem[prop].avatar,
        name: noticeItem[prop].name,
        type: noticeItem[prop].type,
      });
    }
    let arrayFilter = docs.filter((el) => el.type !== "admin");
    let arrayOldOrder = arrayFilter.sort(function (a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    let orderDocs = [];
    // eslint-disable-next-line
    arrayOldOrder.map((elem) => {
      let condicional = orderDocs.find((el) => el.user === elem.user);

      if (!condicional) {
        let array = arrayOldOrder.filter((el) => el.user === elem.user);

        orderDocs.push(array[0]);
      }
    });
    orderDocs.map(async (elem) => {
      let user = await loadUser(elem.user);

      elem.avatar = user.avatar;
      elem.name = user.name;
    });

    callback(orderDocs);
  });

  return unsub;
}
export function watchHomes(callback) {
  const unsub = db.ref("/home");
  unsub.on("value", async (snapshot) => {
    const docs = [];
    // nuevo mensaje
    const noticeItem = snapshot.val();

    for (const prop in noticeItem) {
      await storage
        .ref()
        .child(`/home/${noticeItem[prop].home}`)
        .getDownloadURL()
        .then(
          (url) => {
            docs.push({
              date: noticeItem[prop].date,
              image: url,
              uid: prop,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
    let orderDocs = docs.sort(function (a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    // leer los datos del usuario
    callback(orderDocs);
  });

  return unsub;
}
