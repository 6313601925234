import React, { useContext } from "react";
import { HomeContext } from "../context/Home";
import { Fade } from "react-slideshow-image";
// import './slide.css'
const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
  // onChange: (oldIndex, newIndex) => {
  //   console.log(`fade transition from ${oldIndex} to ${newIndex}`);
  // },
};
function SlideImage() {
  const { home } = useContext(HomeContext);

  return (
  
      <Fade {...fadeProperties}>
        {home.map(({ image, date }) => (
          <div className="each-fade" key={date} style={{zIndex:10}}>
            <div className="image-container">
              <img src={image} alt={date} style={{ width: "100%"}} />
            </div>
          </div>
        ))}
      </Fade>
  
  );
}

export default SlideImage;
