import React from 'react';
import {
    watchUserChanges,
    watchHomes,
    // createHome,
    deleteImageHome,
    // updateNotice,
} from '../services/firebase';

export const HomeContext = React.createContext({});

export const HomeContextConsumer = HomeContext.Consumer;

export class HomeContextProvider extends React.Component {
    state = {
        home: [],
    }

    componentDidMount() {
        this.userWatcherUnsub = watchUserChanges((user) => {
            if (user && !this.expenseWatcherUnsub) {
                this.expenseWatcherUnsub = watchHomes((home) => {
                    this.setState({ home });
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.expenseWatcherUnsub) {
            this.expenseWatcherUnsub();
        }
    }

    deleteImageHome = async (id) => {
        try {
            await deleteImageHome(id);
        } catch (error) {
            console.log(error);
        }
    }

    // createHome = async (data) => {
    //     try {
    //         await createHome(data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // updateExpense = async (id, data) => {
    //     try {
    //         await updateNotice(id, data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    render() {
        const {
            children,
        } = this.props;

        return (
            <HomeContext.Provider
                value={{
                    home:this.state.home,
                    deleteImageHome: this.deleteImageHome,
                    // createHome: this.createHome,
                    // updateNotice: this.updateNotice,
                }}
            >
                {children}
            </HomeContext.Provider>
        );
    }
}
