import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Chat from "./components/chats";
import Profile from "./components/Profile";
import PrivateRoute from "./utils/PrivateRoute";
import Home from "./components/Home/Home";
import Download from "./components/Download";
import PrivateRouteAdmin from "./utils/PrivateRouteAdmin";
import PublicPublic from "./utils/PublicRoute";
import News from "./components/News/News";
// import NewsAdmin from './components/News/NewsAdmin';
import chatAdmin from "./components/chatAdmin";

import Message from "./components/chatAdmin/Message";
import HomeAdmin from "./components/Home/HomeAdmin";
import Contacts from "./components/ContactsAdmin";
import Redir from "./components/Redir";

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute exact path="/chat" component={Chat} />
    <PrivateRoute exact path="/download" component={Download} />
    <PrivateRoute exact path="/news" component={News} />
    <PrivateRoute exact path="/profile" component={Profile} />
    <PrivateRoute exact path="/redirect" component={Redir} />
    <PrivateRouteAdmin exact path="/admin" component={HomeAdmin} />
    <PrivateRouteAdmin exact path="/admin/chat" component={chatAdmin} />
    <PrivateRouteAdmin exact path="/admin/chat/:uid" component={Message} />
    <PrivateRouteAdmin exact path="/admin/news" component={News} />
    <PrivateRouteAdmin exact path="/admin/contacts" component={Contacts} />
    <PrivateRouteAdmin exact path="/admin/profile" component={Profile} />
    <PublicPublic exact path="/login" component={Login} />
    <PublicPublic exact path="/signup" component={Signup} />
    <Redirect to={"/"} />
  </Switch>
);

export default Routes;
