import React, {  useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "@material-ui/core";
// import HomeIcon from "@material-ui/icons/Home";
import NotificationsIcon from "@material-ui/icons/Notifications";
import User from "./User";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "fixed",
    top: "auto",
    bottom: 0,
    backgroundColor: "white",
    padding: 0,
    justifyContent: "center",
    height: "10vh",
  },
  navBottom: {
    width: "100%",
  },
  button: {
    fontSize: 10,
    fontFamily: "Whitney",
    minWidth: 60,
    textDecoration: "none",
  },

  buttonActive: {
    color: "#906d31 !important",
    fill: "#906d31",
    backgroundColor: "#f6f1eb",
    fontSize: 10,
    fontFamily: "Whitney",
    minWidth: 60,
    textDecoration: "none",
  },

  containerLogo: {
    position: "relative",
    width: "100%",
    height: "20vh",
    backgroundColor: "black",
    overflow: "hidden",
  },
  logo: {
    maxWidth: 245,
    height: "100%",
    maxHeight: "10vh",
    backgroundColor: "transparent",
  },
  fab: {
    width: 70,
    height: 70,
    marginBottom: 50,
    marginRight: 15,
    borderRadius: 45,
    border: 0,
    backgroundColor: "white",
    boxShadow: "6px 7px 13px -6px rgba(0,0,0,0.75)",
  },
  iconHome: {
    color: "#656565",
    width:16,
    height:16,
    margin:4
   },
   buttonHome: {
    color: "black",
    padding: 14,
  },
}));

const HeaderAdmin = (props) => {
  const classes = useStyles();
  const { uid } = useParams();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    updateCurrentPath();// eslint-disable-next-line
  }, [window.location.pathname]);// eslint-disable-next-line

  const updateCurrentPath = () => {
    switch (window.location.pathname) {
      case "/admin/contact":
        setValue(0);
        break;

      case "/admin/news":
        setValue(1);
        break;
      case `/admin/chat/${uid}`:
        setValue(2);
        break;
      default:
        setValue(10);
        break;
    }
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.containerLogo}
      >
        <IconButton
          aria-label="Home"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          component={RouterLink}
          to="/admin"
          className={classes.buttonHome}
        >
          <img alt={"home"} src={require('../../assets/HomeIcon.svg')} className={classes.iconHome} />
        </IconButton>
        <Link
          style={{ textAlign: "center", height: "10vh" }}
          component={RouterLink}
          to="/admin"
        >
          <img
            alt="logo"
            className={classes.logo}
            src={require("../../assets/errazuriz.svg")}
          />
        </Link>
        <User />
      </Grid>
      <AppBar id={"app-navbar-button"} className={classes.appBar}>
        <Toolbar
          style={{
            padding: 0,

            textAlign: "center",
            justifyContent: "space-evenly",
          }}
        >
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              console.log(newValue);
            }}
            showLabels
            className={classes.navBottom}
          >
            <BottomNavigationAction
              component={RouterLink}
              to="/admin/contacts"
              className={value === 0 ? classes.buttonActive : classes.button}
              label="CONTACTS"
              icon={<PeopleAltIcon />}
            />
            <BottomNavigationAction
              component={RouterLink}
              to="/admin/news"
              className={value === 1 ? classes.buttonActive : classes.button}
              label="NEWS"
              icon={<NotificationsIcon />}
            />
            <BottomNavigationAction
              component={RouterLink}
              to="/admin/chat"
              label=""
              icon={
                <div style={{ width: 130 }}>
                  <button
                    className={classes.fab}
                    to="/admin/chat"
                    tag={RouterLink}
                  >
                    <img
                      alt="msj"
                      src={require("../../assets/icono_chat.svg")}
                      style={{ width: 50, height: 50 }}
                    />
                  </button>
                </div>
              }
            />
          </BottomNavigation>
        </Toolbar>
      </AppBar>
      {props.children}
    </div>
  );
};

export default HeaderAdmin;
